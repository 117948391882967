
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";

import Unit from "../../models/sporteventCalculation/Unit";
import UnitService from "../../services/sporteventCalculation/UnitService";

@Component
export default class UnitComponent extends Vue {
  public unit: Unit = new Unit();

  mounted(): void {
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      UnitService.get(this.$route.params.id).then((item: Unit) => {
        this.unit = item;
      });
    }
  }

  save(): void {
    UnitService.save(this.unit).then((item: Unit) => {
      ToastService.Success(i18n.tc("labels.saved"), item.name);
      if (item) {
        this.unit = item;
        this.handleBack();
      }
    });
  }

  handleBack(): void {
    this.$router.back();
  }
}
