import Unit from "@/models/sporteventCalculation/Unit";

import BaseService from "../BaseService";

class UnitService extends BaseService<Unit> {
  public url: string;

  constructor(url = "sportevent-calculation/unit/") {
    super(url, "");
    this.url = url;
  }
}
export default new UnitService();
